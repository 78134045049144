import * as React from "react";

import LayoutComponent from "../components/layout";
import CarouselComponent from "../components/carousel/carousel";

// markup
const FicheVehicule = () => {
  return (
    <LayoutComponent>
      <CarouselComponent />
    </LayoutComponent>
  );
};
export default FicheVehicule;
