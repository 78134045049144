import * as React from "react";
import { Carousel, Container } from "react-bootstrap";

// markup
const CarouselComponent = () => {
  return (
    <Container>
      <h1>Nissan Rogue 2021</h1>
      <h6 className="text-muted">MIDNIGHT EDITION</h6>
      <h6 className="text-muted">62,827 km</h6>
      <Carousel interval="5000">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://heliotvilchis.com/dakaramerica/static/r1-a8166c78da3f5826e644c267bfbc26b6.jpg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://heliotvilchis.com/dakaramerica/static/r1-a8166c78da3f5826e644c267bfbc26b6.jpg"
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://heliotvilchis.com/dakaramerica/static/r1-a8166c78da3f5826e644c267bfbc26b6.jpg"
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
      <h3 class="text-center">
        <b>123 456 FCFA</b>
      </h3>
    </Container>
  );
};
export default CarouselComponent;
